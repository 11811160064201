.results-page{
  background-image: url("../Assets/background.png");
  height: 100vh;
  width: 100vw;
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  .title-box {
    font-family: ccsignlanguage, sans-serif;
    font-weight: 400;
    font-size: 45pt;
    text-shadow: 9px 7px #262F6A;
    color: #EFC519;
    margin: 0!important;
    padding-top: 0;
    text-align: center;
  }

  h2{
    text-align: center;
    color: #262F6A;
    font: 48pt Brevia;
    font-weight: 500;
    width: 60%;
  }

  .response-box{
    display: flex;
    justify-content: center;
    margin: 40px 40px 0;
  }
  a{
    text-decoration: none;
  }
  .home-button{
    display: flex;
    width: 47vw;
    background-color: #52C5D8;
    border-radius: 40px;
    margin: 0 auto 0 auto;
    padding: 30px;
    font: 52px Brevia;
    font-weight: 500;
    color: white;
    text-align: center;
  }
  video{
    pointer-events: none;
    position: absolute;
    z-index: 2;
    width: 300px;
  }
  .sparkle-left{
    top: 40%;
    left: 10%;
  }
  .sparkle-right{
    top: 40%;
    right: 10%;
  }
}