@keyframes wrong {
  0%{transform: rotate(0deg)}
  10%{transform: rotate(15deg)}
  20%{transform: rotate(-15deg)}
  30%{transform: rotate(15deg)}
  40%{transform: rotate(-15deg)}
  50%{transform: rotate(15deg)}
  60%{transform: rotate(-15deg)}
  70%{transform: rotate(15deg)}
  80%{transform: rotate(-15deg)}
  90%{transform: rotate(15deg)}
  100%{transform: rotate(0deg)}
}

.question-page {
  background-image: url("../Assets/background.png");
  height: 100vh;
  width: 100vw;

   .title-box {
    font-weight: 400;
    font: 75pt ccsignlanguage;
    text-shadow: 4px 7px #262f6a;
    color: #EFC519;
    margin: 0;
  }

  .question-box {
    font: 18pt Brevia;
    font-weight: 500;
    padding-top: 3%;
    color: #262F6A;
  }

  .button-box{
    width: 60%;
    height: 50%;
    padding-top: 5%;
    display: flex;
    flex-wrap: wrap;
  }

  .incorrectButton {
    background-color: #EE7526;
    animation: wrong 1s ease-in-out;
  }

  .defaultButton {
    background-color: #52C5D8;
  }

  .correctButton {
    background-color: #6fcf97;
  }

  .button-box-none {
    display: none;
  }

  button{
    font: 30px Brevia;
    font-weight: 600;
    width: 20vw;
    height: 14vh;
    color: white;
    margin: 0 100px 60px 0;
    border: none;
    border-radius: 20px;
    background-color: #52C5D8;
    vertical-align: top;
  }

  .nextButton{
    position: absolute;
    bottom: 100px;
    right: 66px;
  }

  .incorrect-response-box{
    background-color: #EE7526;
    width: 1000px;
    border-radius: 50px;
    position: absolute;
    bottom: 150px;
    font: 32px Brevia;
    font-weight: 400;
    color: white;
    padding: 45px 50px;
  }

  .correct-response-box{
    background-color: #6FCF97;
    width: 1000px;
    border-radius: 50px;
    position: absolute;
    bottom: 150px;
    font: 32px Brevia;
    font-weight: 400;
    color: white;
    padding: 45px 50px;
  }

  .content-container{
    padding: 5% 0 0 5%;
  }

  .question-box .category-tag{
    font-weight: 600 !important;
  }

  .pun{
    font-weight: 600 !important;
  }

  video{
    pointer-events: none;
    position: absolute;
    z-index: 2;
    width: 150px;
  }

  .video-bottom-right{
    right: 19px;
    bottom: -18px;
  }

  .video-top-left{
    left: -65px;
    top: -93px;
  }

  .button-div{
    position: relative;
  }
}