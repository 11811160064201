@keyframes rotate {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(-360deg);
  }
}
@keyframes grow {
  from {
  }
  to {
    transform: scale(100000%)
  }
}
.start-page {
  width: 100vw;
  height: 100vh;
  display: flex;
  flex-direction: column;

  .title-box {
    font-family: ccsignlanguage, sans-serif;
    font-weight: 400;
    font-size: 45pt;
    text-shadow: 9px 7px #262f6a;
    color: #EFC519;
    margin: -2% auto 0;
    text-align: center;
  }

  .description-box {
    font: 30pt Brevia;
    text-align: center;
    color: #262F6A;
    margin: -2% auto 0% auto;
    width: 45%;
  }

  .icon-box {
    display: flex;
    margin: auto 0;
    justify-content: center;
    p {
      font-size: 21pt;
      max-width: 195px;
      text-align: center;
      font-weight: bold;
    }
    img{
      width: 100%;
      will-change: transform;
      animation: rotate 6s infinite linear;
    }
  }

  .icon{
    padding-left: 2.5%;
    padding-right: 2.5%;
    display: flex;
    flex-direction: column;
    width: 10%;

    &:hover{
      will-change: transform;
      animation: grow 2s 1 ease-in forwards;
    }
  }
}